import React, { useEffect, useRef, useState } from 'react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
  WidgetProps,
} from 'yoshi-flow-editor-runtime';
import { useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { TimezoneType } from '@wix/bookings-uou-types';

import { SchedulingLocationViewModel } from '../../../service-page-view-model/scheduling-location-view-model/schedulingLocationViewModel';
import { SchedulingTimezoneViewModel } from '../../../service-page-view-model/shceduling-timezone-view-model/schedulingTimezoneViewModel';
import { SchedulingSectionViewModel } from '../../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import { ServicePageViewModel } from '../../../service-page-view-model/servicePageViewModel';
import { useVisitorBILogger, WidgetBILoggerProvider } from '../bi/biContext';
import { BookButtonViewModelProvider } from './useBookButtonViewModel';
import { WidgetEventsProvider } from './useWidgetEvents';
import { ISection, SectionTypes, SidebarPosition } from '../types';
import { classes, st } from './Widget.st.css';

import UserMessage from './UserMessage/UserMessage';
import Scheduling from './Scheduling/Scheduling';
import TitleAndTagline from './TitleAndTagline';
import settingsParams from '../settingsParams';
import Description from './Description';
import Sidebar from './Sidebar/Sidebar';
import Gallery from './Gallery/Gallery';
import EmptyState from './EmptyState';
import Header from './Header/Header';
import Policy from './Policy/Policy';
import Details from './Details';
import Contact from './Contact';
import Body from './Body/Body';
import { useSizeListener } from './useSizeListener';
import { DimensionsProvider } from './useDimensions';
import { useWidgetSections } from './useWidgetSections';

export interface ControllerProps {
  viewModel?: ServicePageViewModel;
  scheduleViewModel?: SchedulingSectionViewModel;
  locationViewModel?: SchedulingLocationViewModel;
  timezoneViewModel?: SchedulingTimezoneViewModel;
  changeTimezoneCallback?: (timezoneType: TimezoneType) => void;
  changeLocationCallback?: (locationId: string) => void;
  navigateToCalendar: (referralInfo?: string) => Promise<void>;
  userMessage?: {
    shouldDisplayMessage: boolean;
    closeMessage: () => void;
  };
}

const getSectionComponentByType = (
  section: ISection,
  viewModel: ServicePageViewModel,
  schedule?: SchedulingSectionViewModel,
  locationViewModel?: SchedulingLocationViewModel,
  timezoneViewModel?: SchedulingTimezoneViewModel,
) => {
  switch (section.type) {
    case SectionTypes.TITLE_TAGLINE:
      return (
        <TitleAndTagline
          key={section.type}
          section={section}
          viewModel={viewModel.titleAndTagline}
        />
      );
    case SectionTypes.SCHEDULING:
      if (viewModel.showSchedulingSection) {
        return (
          <Scheduling
            key={section.type}
            section={section}
            schedule={schedule}
            locationViewModel={locationViewModel}
            timezoneViewModel={timezoneViewModel}
          />
        );
      }
      break;
    case SectionTypes.POLICY:
      return <Policy key={section.type} viewModel={viewModel.policySection} />;
    case SectionTypes.DETAILS:
      return (
        <Details
          key={section.type}
          section={section}
          viewModel={viewModel.detailsSection}
        />
      );
    case SectionTypes.DESCRIPTION:
      return (
        <Description
          key={section.type}
          section={section}
          viewModel={viewModel.descriptionSection}
        />
      );
    case SectionTypes.CONTACT:
      return (
        <Contact
          key={section.type}
          section={section}
          viewModel={viewModel.contactSection}
        />
      );
    case SectionTypes.GALLERY:
      return (
        <Gallery key={section.type} viewModel={viewModel.gallerySection} />
      );
    default:
      return null;
  }
};

export const Widget: React.FC<WidgetProps<ControllerProps>> = ({
  viewModel,
  scheduleViewModel,
  locationViewModel,
  timezoneViewModel,
  changeTimezoneCallback,
  changeLocationCallback,
  navigateToCalendar,
  userMessage,
}) => {
  const { t } = useTranslation();
  const { isMobile, isRTL } = useEnvironment();
  const settings = useSettings();
  const { experiments } = useExperiments();
  const biLogger = useVisitorBILogger();
  const widgetRef = useRef<HTMLDivElement>(null);
  const widgetBoundings = useSizeListener(widgetRef);
  const sections = useWidgetSections();
  const getSectionByType = (sectionType: SectionTypes): ISection => {
    return sections.find(({ type }) => type === sectionType) as ISection;
  };

  const isSectionVisible = (section: ISection): boolean => {
    if (
      !experiments.enabled('specs.bookings.ClientGallery') &&
      section.type === SectionTypes.GALLERY
    ) {
      return false;
    }
    return (
      section.visible &&
      (!settings.get(settingsParams.sidebarVisibility) ||
        section.type !== settings.get(settingsParams.sidebarSection))
    );
  };

  const shouldShowOnlyImage =
    settings.get(settingsParams.headerImageVisibility) &&
    !settings.get(settingsParams.headerBookButtonVisibility) &&
    !settings.get(settingsParams.headerTitleVisibility);
  const isServiceWithoutImage = !viewModel?.header?.image;
  const shouldShowHeader =
    settings.get(settingsParams.headerVisibility) &&
    !(shouldShowOnlyImage && isServiceWithoutImage);

  const renderBody = (widgetViewModel) => (
    <Body viewModel={widgetViewModel.body}>
      {sections.map((section) =>
        isSectionVisible(section)
          ? getSectionComponentByType(
              section,
              widgetViewModel,
              scheduleViewModel,
              locationViewModel,
              timezoneViewModel,
            )
          : null,
      )}
    </Body>
  );

  const renderSideBar = (widgetViewModel) =>
    settings.get(settingsParams.sidebarVisibility) ? (
      <Sidebar sectionType={settings.get(settingsParams.sidebarSection)}>
        {getSectionComponentByType(
          getSectionByType(settings.get(settingsParams.sidebarSection)),
          widgetViewModel,
          scheduleViewModel,
          locationViewModel,
          timezoneViewModel,
        )}
      </Sidebar>
    ) : null;

  useEffect(() => {
    biLogger.bookingsServicesPageView({
      emptyState: !viewModel,
      message_to_user: viewModel?.body.messageType,
      platform: isMobile ? 'mobile' : '',
      containers: viewModel
        ? `body${shouldShowHeader ? ',header' : ''}${
            settings.get(settingsParams.sidebarVisibility) ? ',sidebar' : ''
          }`
        : '',
      sections: viewModel ? JSON.stringify(sections) : '',
    });
  }, []);

  const isSideBarOnTheRight = () =>
    settings.get(settingsParams.sidebarPosition) === SidebarPosition.RIGHT;
  const isNoHeaderAndTitleInSideBar = () => {
    const isSideBarVisible = settings.get(settingsParams.sidebarVisibility);
    const isSideBarSectionIsTitleAndTagline =
      settings.get(settingsParams.sidebarSection) ===
      SectionTypes.TITLE_TAGLINE;
    const isSideBarTitleVisible = settings.get(
      settingsParams.serviceTitleVisibility,
    );
    return (
      isNoHeaderAndTitleInSideBarExperiment &&
      !shouldShowHeader &&
      isSideBarVisible &&
      isSideBarSectionIsTitleAndTagline &&
      isSideBarTitleVisible
    );
  };
  const isNoHeaderAndTitleInSideBarExperiment = experiments.enabled(
    'specs.bookings.NoHeaderAndTitleInSideBar',
  );
  const inverseRowBySideBarPositionAndNoHeaderLogic =
    isNoHeaderAndTitleInSideBarExperiment &&
    ((isNoHeaderAndTitleInSideBar() && isSideBarOnTheRight()) ||
      (!isNoHeaderAndTitleInSideBar() && !isSideBarOnTheRight()));

  const isRenderBackgroundURLOnSSR = experiments?.enabled(
    'specs.bookings.RenderBackgroundURLOnServicePageOnSSR',
  );

  return (
    <WidgetEventsProvider
      value={{
        changeTimezoneCallback,
        changeLocationCallback,
        navigateToCalendar,
      }}
    >
      <BookButtonViewModelProvider value={viewModel?.button}>
        <DimensionsProvider
          value={{
            width: widgetBoundings.width,
            height: widgetBoundings.height,
          }}
        >
          <div
            className={st(classes.root, {
              sidebarPosition: settings.get(settingsParams.sidebarPosition),
              isMobile,
              isRTL: isRTL && !isNoHeaderAndTitleInSideBarExperiment,
              isNoHeaderAndTitleInSideBar: inverseRowBySideBarPositionAndNoHeaderLogic,
            })}
            ref={widgetRef}
          >
            {viewModel ? (
              <>
                <UserMessage
                  isOpen={userMessage?.shouldDisplayMessage || false}
                  onRequestClose={userMessage?.closeMessage || (() => {})}
                  isMobile={isMobile}
                  message={t('app.widget.uou-messages.non.premium.message')}
                  okLabel={t('app.widget.uou-messages.non.premium.approve')}
                  isRTL={
                    experiments.enabled(
                      'specs.bookings.rtlAlignmentInModals',
                    ) && isRTL
                  }
                />
                <div
                  className={classes.root}
                  data-hook="booking-service-page-wrapper"
                >
                  {shouldShowHeader && (
                    <Header
                      viewModel={viewModel.header}
                      isRenderBackgroundURLOnSSR={isRenderBackgroundURLOnSSR}
                    />
                  )}
                  <div className={classes.dynamicWrapper}>
                    {isNoHeaderAndTitleInSideBarExperiment ? (
                      isNoHeaderAndTitleInSideBar() ? (
                        <>
                          {renderSideBar(viewModel)}
                          {renderBody(viewModel)}
                        </>
                      ) : (
                        <>
                          {renderBody(viewModel)}
                          {renderSideBar(viewModel)}
                        </>
                      )
                    ) : settings.get(settingsParams.sidebarPosition) ===
                      (isRTL ? SidebarPosition.RIGHT : SidebarPosition.LEFT) ? (
                      <>
                        {renderSideBar(viewModel)}
                        {renderBody(viewModel)}
                      </>
                    ) : (
                      <>
                        {renderBody(viewModel)}
                        {renderSideBar(viewModel)}
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <EmptyState />
            )}
          </div>
        </DimensionsProvider>
      </BookButtonViewModelProvider>
    </WidgetEventsProvider>
  );
};

export default (props: WidgetProps<ControllerProps>) => (
  <WidgetBILoggerProvider>
    <Widget {...props} />
  </WidgetBILoggerProvider>
);
